import { render } from 'lit-html'
import { autorun } from 'mobx'

import State from './state'

import adumbro from './adumbro'

State.rehydrate()
autorun(() => {
  render(adumbro(), document.body)
  State.persist()
})
